<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form">
      <a-form-model-item v-if="form.pic" label="线索图片" prop="pics" >
        <div v-viewer>
          <img width="200" height="200" v-for="item in form.pic.split(',')" :src="item" />
        </div>
      </a-form-model-item>
      <a-form-model-item label="线索内容" prop="content" >
        <a-input :disabled=true v-model="form.content" />
<!--        <editor readOnly v-model="form.content" />-->
      </a-form-model-item>
      <a-form-model-item label="地区" prop="address" >
        <a-input :disabled=true v-model="form.address" />
      </a-form-model-item>
<!--      <a-form-model-item label="省份id" prop="provinceId" >
        <a-input v-model="form.provinceId" placeholder="请输入省份id" />
      </a-form-model-item>
      <a-form-model-item label="城市id" prop="cityId" >
        <a-input v-model="form.cityId" placeholder="请输入城市id" />
      </a-form-model-item>
      <a-form-model-item label="区域id" prop="areaId" >
        <a-input v-model="form.areaId" placeholder="请输入区域id" />
      </a-form-model-item>-->
<!--      <a-form-model-item label="发布方式,0-匿名发布,1-实名发布" prop="releaseType" >
      </a-form-model-item>
      <a-form-model-item label="线索类型,0-关联事件,1-社区发布" prop="type" >
      </a-form-model-item>-->
      <a-form-model-item label="浏览数量" prop="viewNum" >
        <a-input :disabled=true v-model="form.viewNum" placeholder="请输入浏览数量" />
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>-->
          <a-button type="dashed" @click="cancel">
            关闭
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getClue, addClue, updateClue } from '@/api/clue/clue'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        pic: null,

        content: null,

        provinceId: null,

        cityId: null,

        areaId: null,

        releaseType: null,

        type: null,

        viewNum: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        provinceId: [
          { required: true, message: '省份id不能为空', trigger: 'blur' }
        ],
        cityId: [
          { required: true, message: '城市id不能为空', trigger: 'blur' }
        ],
        areaId: [
          { required: true, message: '区域id不能为空', trigger: 'blur' }
        ],
        releaseType: [
          { required: true, message: '发布方式,0-匿名发布,1-实名发布不能为空', trigger: 'change' }
        ],
        type: [
          { required: true, message: '线索类型,0-关联事件,1-社区发布不能为空', trigger: 'change' }
        ],
        viewNum: [
          { required: true, message: '浏览数量不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        pic: null,
        content: null,
        provinceId: null,
        cityId: null,
        areaId: null,
        releaseType: null,
        type: null,
        viewNum: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getClue({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 详情按钮操作 */
    handleInfo (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getClue({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateClue(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addClue(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

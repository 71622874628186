import request from '@/utils/request'


// 查询线索列表
export function listClue(query) {
  return request({
    url: '/clue/clue/list',
    method: 'get',
    params: query
  })
}

// 查询线索分页
export function pageClue(query) {
  return request({
    url: '/clue/clue/page',
    method: 'get',
    params: query
  })
}

// 查询线索详细
export function getClue(data) {
  return request({
    url: '/clue/clue/detail',
    method: 'get',
    params: data
  })
}

// 新增线索
export function addClue(data) {
  return request({
    url: '/clue/clue/add',
    method: 'post',
    data: data
  })
}

// 修改线索
export function updateClue(data) {
  return request({
    url: '/clue/clue/edit',
    method: 'post',
    data: data
  })
}

// 删除线索
export function delClue(data) {
  return request({
    url: '/clue/clue/delete',
    method: 'post',
    data: data
  })
}
